import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import HeroSection from '../components/HeroSection';
import SEO from '../components/SEO';

const NotFoundPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          backgroundCityImage: file(relativePath: { eq: "cityscape.jpg" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Layout>
            <SEO title="404: Not found" />
            <HeroSection
              color="primary"
              size="large"
              backgroundImage={
                <Img fluid={data.backgroundCityImage.childImageSharp.fluid} />
              }
              backgroundImageOpacity={0.3}
              title="Page Not Found"
              subtitle="You just hit a route that doesn’t exist... the sadness."
              actionText="Back Home"
              actionURL="/"
            />
          </Layout>
        );
      }}
    />
  );
};

export default NotFoundPage;
